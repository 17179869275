@import "~bootstrap/scss/bootstrap";

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.margin-nav {
  margin-top: 93px !important;
  @media (max-width: 640px) {
    margin-top: 69px !important;
  }
}
//navbar
.widthsm {
  @media (max-width: 640px) {
    width: 100% !important;
  }
  @media (max-width: 768px) and (min-width: 641px) {
    width: 100% !important;
  }
}
.swiper-wrapper {
  display: flex !important;
}
.navbar-nav {
  @media (max-width: 768px) and (min-width: 641px) {
    display: block !important;
  }
  @media (max-width: 640px) {
    display: block !important;
  }
}
@media (max-width: 640px) {
  // div#basic-navbar-nav {
  //   background: black;
  //   width: 100%;
  //   margin-top: 11rem;
  // }
}
.h-100vh {
  height: 100vh !important;
}
.loaderss {
  border: 16px solid #f3f3f3 !important;
  border-top: 16px solid red;
  border-radius: 50% !important;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.iframe {
  width: 500px;
  height: 650px;

  @media (max-width: 640px) {
    width: 100%;
    height: 300px;
  }
}

.underlino {
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
  @media (max-width: 640px) {
    font-weight: 300 !important;
  }
}
.underlino-blck {
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000 !important;
  @media (max-width: 640px) {
    font-weight: 300 !important;
  }
}
a#nav-dropdown-dark-example {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  @media (max-width: 640px) {
    font-weight: 300 !important;
  }
}
.navbar-toggler {
  background-color: #ececec !important;
  font-size: 15px !important;
}

//opacity
.filter-b3 {
  filter: brightness(0.3) !important;
}
.filter-b5 {
  filter: brightness(0.5) !important;
}
.filter-b6 {
  filter: brightness(0.6) !important;
}

//home
.h-banner {
  height: 650px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 450px !important;
  }
  @media (max-width: 640px) {
    height: 500px !important;
  }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  margin-right: 50%;
  height: 550px;
  border-radius: 25px;
  @media (max-width: 640px) {
    height: 330px !important;
  }
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 100px;
  @media (max-width: 640px) {
    height: 90px !important;
  }
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  object-fit: contain !important;
}
//bootstrap
.dropdown-menu.show {
  border-radius: 0px 0px 7px 7px !important;
  border: 1px solid #f2f2f2 !important;
  padding-left: 7px !important;
  font-size: 15px !important;
  padding-right: 7px !important;
  padding-bottom: 15px !important;
  padding-top: 10px !important;
  font-family: "Poppins", sans-serif !important;
  left: -110px !important;
  @media (max-width: 640px) {
    box-shadow: 0px 0px 0px 0px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    border: 1px solid #ececec !important;
    width: 100% !important;
    left: 0px !important;
  }
}
a#nav-dropdown-dark-example {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

//swiper
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 32px !important;
}
.swiper {
  height: auto;
}
@media screen and (min-width: 576px) {
  .swiper-container {
    width: 576px;
  }
}
@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}
@media (min-width: 992px) {
  .swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//overflow
.overflow-verticle {
  overflow-x: auto !important;
}

//height
.h-100vh {
  height: 100vh !important;
}
.h-90vh {
  height: 88vh !important;
}
.zindex {
  z-index: 999999 !important;
}
.ptb-0px {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.normal-hidden {
  display: block !important;
  @media (max-width: 640px) {
    display: none !important;
  }
}
.default-hidden {
  display: none !important;
  @media (max-width: 640px) {
    display: block !important;
  }
}
@media (max-width: 640px) {
  .sidebar-overlay {
    width: 80% !important;
  }
  .mini-margin {
    margin-top: 12px !important;
  }
}
.radio {
  width: 20px !important;
  height: 20px !important;
  color: #444 !important;
  background-color: #444 !important;
}
.radio:active {
  width: 20px !important;
  height: 20px !important;
  color: #444 !important;
  background-color: #444 !important;
}
.bg-light {
  background-color: #ececec !important;
  border-radius: 50% !important;
}
.el-upload {
  width: 100% !important;
}
.el-upload-dragger {
  width: 100% !important;
}
.fade.modal.show {
  z-index: 999999999 !important;
}
.textsecondary {
  color: var(--qtheme-color-secondary) !important;
}
@media (max-width: 640px) {
  .padresponsive {
    padding-top: 70px;
  }
}

.bottom-ifrmae {
  margin-bottom: -17% !important;
  @media (max-width: 640px) {
    margin-bottom: -22% !important;
  }
}
.pad-mini {
  padding: 9px !important;
  @media (max-width: 640px) {
    padding: 7px !important;
  }
}
.bg-primary {
  background: var(--qtheme-color-primary) !important;
}
.bgprimary {
  background: var(--qtheme-color-primary) !important;
}
.bgsecondary {
  background: var(--qtheme-color-secondary) !important;
}
.textprimary {
  color: var(--qtheme-color-primary) !important;
}
.text-primary {
  color: var(--qtheme-color-primary) !important;
}
.hoverunder:hover {
  text-decoration: underline !important;
}
.bg-glass {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(1px) !important;
}
.align-center {
  align-items: center !important;
}
.counter-cs {
  display: flex;
  align-items: center;
  border: 1px solid #8080804a;
  border-radius: 6px;
  justify-content: center;
}
.btn-square {
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: 1px solid #444 !important;
  color: #444 !important;
}

.flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mr-12px {
  margin-right: 12px;
}
.sidebarclass.sidebarfixcls {
  z-index: 999999999 !important;
}
.swipe-height {
  height: 600px !important;
}
.el-carousel__mask {
  display: block !important;
}
.bordb {
  border-bottom: 1px solid #ececec !important;
}
.bordb2 {
  border-bottom: 1px solid #111 !important;
}
@media (max-width: 640px) {
  .nav-pad {
    padding: 10px !important;
  }
  .mobile {
    display: hidden !important;
  }
  .swip .el-carousel__container {
    height: 100px !important;
  }
}
.braek {
  word-break: break-word !important;
}
.dropdown-item:active {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item:focus {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item:hover {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}

.dropdown-item a:active {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item a:focus {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item a:hover {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item {
  color: #444 !important;
}
.car-height .el-carousel__container {
  height: 600px !important;
  @media (max-width: 640px) {
    height: 450px !important;
  }
}
.borderltb {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  @media (max-width: 640px) {
    border-radius: 12px;
  }
}
.opactid {
  opacity: 0 !important;
}
.height-privacy {
  height: 350px !important;
  @media (max-width: 640px) {
    height: 290px !important;
  }
}

.mini-top {
  margin-top: 12px !important;
}
.list {
  list-style-type: disc !important;
  margin-left: 20px !important;
}
.bg-light-success {
  background-color: #c0ffbb !important;
}
.bg-light-danger {
  background-color: #ffcfd5 !important;
}
.max-min {
  min-width: 0px !important;
}
.input {
  border: 1px solid #a6adb778;
  font-size: 14px;
  border-radius: 6px;
  padding: 0.5rem;
}
.input:focus {
  outline: none !important;
}
.elshadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.phoneinput .form-control {
  width: 100% !important;
  padding: 0.5rem 14px 0.5rem 50px !important;
}
.react-tel-input .country-list {
  width: 350px !important;
}
.verifyemail {
  background: #6a2c70;
  padding: 3px 10px;
  border-radius: 5px;
  color: #fff;
  top: 16px;
  cursor: pointer;
}
.resendotp-t [type="text"] {
  border: 1px solid #a6adb778;
  font-size: 14px;
  border-radius: 6px;
  width: 40px !important;
  height: 40px !important;
}

@media only screen and (min-width: 600px) {
  .eventsNav {
    width: 65% !important;
    background: #fff !important;
    position: sticky !important;
    top: 69px !important;
    padding: 20px !important;
    margin: auto !important;
    left: 0% !important;
  }
}

.new-drop .dropdown-menu {
  position: absolute !important;
  top: 45px !important;
  height: none !important;
  background: #fff !important;
  left: -45px !important;
}
.rounded-cancel {
  border-radius: 0px !important;
}
.mega-menu .dropdown-menu {
  position: fixed !important;
  left: 0px !important;
  top: 69px !important;
  width: 100% !important;
  height: 100vh !important;
  background: #11111145 !important;
  opacity: 2 !important;
  opacity: filter !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
a#basic-nav-dropdown {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.borderb {
  border-bottom: 1px solid #ececec !important;
}

.clr-black {
  color: #000;
}

.hidden {
  display: block;
  @media (max-width: 641px) {
    display: none !important;
  }
}

.loader-set {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  background: #fff;
  z-index: 9999999;
  fill: var(--qtheme-color-primary) !important;
}

.txt-center {
  text-align: center;
}

.fsize38px {
  font-size: 38px;
}

.testimpnial-p {
  padding: 8px 0;
  color: #808080c2;
}
.clr-80808073 {
  color: #80808073;
}
.clr-808080c2 {
  color: #808080c2;
}

.hr {
  width: 2.5rem;
  background-color: #c8c8c861;
  height: 5px;
  /* text-align: center; */
  margin: auto;
  border-radius: 3px;
}

.border-c8c8c861 {
  border: 2px solid #c8c8c8;
}

.cust-w100 .react-reveal {
  width: 100%;
  @media (max-width: 575px) {
    width: 100%;
  }
}
.cust-wh100 .react-reveal {
  width: 100%;
  height: 100%;
  @media (max-width: 575px) {
    width: 100%;
    height: 100%;
  }
}
.cust-grid .react-reveal {
  grid-column: 2 / span 2 !important;
}

.popup {
  border-color: black;
  border-radius: 0 !important;
}
button#button-asson2 {
  background-color: black !important;
  color: white;
}

.modal-header {
  border-bottom: none !important;
}

.modal-dialog {
  height: 80vh;

  display: flex;
  align-items: center;
}
.item:nth-child(4n-7) {
  grid-column: span 2 / span 2;
}

.item:nth-child(4n-4) {
  grid-column: 2 / span 2;
}

// .subcat:nth-child(3n-1) {
//   grid-column: 1 / span 2;
// }

// .subcat:nth-child(n + 2):nth-child(n + 5) {
//   grid-column: 1 / span 2;
// }
.subcat:nth-child(12n + 7):nth-child(n + 7) {
  grid-column: 1 / span 2;
}
.subcat:nth-child(6n + 5):nth-child(n + 5) {
  grid-column: 2 / span 2;
}
.subcat:nth-child(12n + 3):nth-child(n + 3) {
  grid-column: 3 / span 2;
}

.impcat:nth-child(10n + 2):nth-child(n + 2) {
  grid-column: 2 / span 2;
}
.impcat:nth-child(10n + 6):nth-child(n + 6) {
  grid-column: 1 / span 2;
}

// .impcat:nth-child(6n + 5):nth-child(n + 5) {
//   grid-column: 2 / span 2;
// }

.textuperrcase {
  text-transform: uppercase;
}

.cust-mt {
  margin-top: 85px !important;
  @media (max-width: 575px) {
    margin-top: 86px !important;
  }
}
.fsize15 {
  font-size: 15px !important;
  @media (max-width: 575px) {
    font-size: 15px !important;
  }
}
.c-fixed {
  position: fixed !important;
}

.instafeed-height {
  height: 580px !important;
  @media (max-width: 575px) {
    height: 500px !important;
  }
}
///
.cust-mt5rem.PhotoView-Portal {
  margin-top: 5rem !important;
}

.zoom {
  transition: 0.3s;
}
.zoom:hover {
  transform: scale(1.1);
}
// .img-skelton{
//   width: 100%;
//   height: 55px;
//   background-color: #eaeaea;

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 0.5rem;
// }
.h-680px {
  height: 680px;
}

.main {
  display: grid;
  grid-template-areas: "gallery";
}
.main__gallery {
  grid-area: gallery;
}

.gallery {
  display: flex;
  gap: var(--size-2);
  justify-content: center;
  flex-wrap: wrap;
  padding: var(--size-2);
  padding-bottom: 10em;
}
.gallery::before {
  background: linear-gradient(to bottom, transparent, white 70%);
  position: fixed;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 20vh;
}
.gallery__col {
  flex: calc(33.33% - var(--size-2));
  max-width: calc(33.33% - var(--size-2));
}
@media screen and (max-width: 48em) {
  .gallery__col {
    flex: calc(50% - var(--size-2));
    max-width: calc(50% - var(--size-2));
  }
}
@media screen and (max-width: 36em) {
  .gallery__col {
    flex: calc(100% - var(--size-2));
    max-width: calc(100% - var(--size-2));
  }
}
.gallery__img {
  margin-bottom: var(--size-2);
}
.imgcss-imp {
  object-fit: cover;
  width: 100%;
  @media (min-width: 576px) {
    height: 380px;
  }
  @media (max-width: 576px) {
    height: 300px;
  }
}
.PhotoView-Slider__BannerRight {
  display: none !important;
}
