/* new styles added */

.swiper-button svg {
  width: 1.5rem;
  height: 1.5rem;
}
.image-swiper-button-prev {
  position: absolute !important;
  left: 5px;
  z-index: 99999 !important;
  top: 50% !important;
  color: var(--qtheme-color-secondary) !important;
}
.image-swiper-button-next {
  position: absolute !important;
  right: 5px;
  z-index: 99999 !important;
  top: 50% !important;
  color: var(--qtheme-color-secondary) !important;
}
.swiper-button-disabled {
  opacity: 0.5;
}
.image-swiper-button-prevs {
  position: absolute !important;
  right: 65px !important;
  bottom: 6px !important;
  color: var(--qtheme-color-secondary) !important;
}
.image-swiper-button-nexts {
  position: absolute !important;
  right: 25px !important;
  bottom: 6px !important;
  color: var(--qtheme-color-secondary) !important;
}
.swiper {
  width: 100% !important;
}

.newswiper {
  width: 660px !important;
  @media (max-width: 641px) {
    width: auto;
  }
}
.image-swiper-button-prevl {
  position: absolute !important;
  z-index: 99999 !important;
  right: 72px !important;
  bottom: 6px !important;
  color: var(--qtheme-color-secondary) !important;

  @media (max-width: 768px) and (min-width: 641px) {
    right: 52px !important;
    bottom: 24px !important;
  }
}
.image-swiper-button-nextl {
  position: absolute !important;
  z-index: 99999 !important;
  right: 32px !important;
  bottom: 6px !important;
  color: var(--qtheme-color-secondary) !important;
  @media (max-width: 768px) and (min-width: 641px) {
    right: 12px !important;
    bottom: 24px !important;
  }
}
.swiper-slide {
  @media (max-width: 641px) {
    width: 330px !important;
  }
}
